import React from 'react';
import '../styles/components/Publicarrusel.css';

const Publicarrusel = () => (
  <div className="container-xxl slider">
    <div className="slide-track">
      <div className="slide">
        <img
          src="https://apiwebtm.com/uploads/Blanco_y_Azul_Bokeh_Pagina_Web_Banner_2_1_a2170ec9d5.png"
          alt="Personaliza tu tarjeta transmetro"
        />
      </div>
      <div className="slide">
        <img
          src="https://apiwebtm.com/uploads/Blanco_y_Azul_Bokeh_Pagina_Web_Banner_3_a04bc3618a.png"
          alt="Arriendo Locales Comerciales"
        />
      </div>
      <div className="slide">
        <img
          src="https://apiwebtm.com/uploads/IMG_20220510_WA_0018_8a478394fa.jpg"
          alt="Personaliza tu tarjeta transmetro"
        />
      </div>

      <div className="slide">
        <img
          src="https://apiwebtm.com/uploads/Blanco_y_Azul_Bokeh_Pagina_Web_Banner_3_a04bc3618a.png"
          alt="Arriendo Locales Comerciales"
        />
      </div>
      {/*  <div className="slide">
        <img src="https://i.ibb.co/VwLzfzq/1.jpg" alt="" />
      </div>
      <div className="slide">
        <img src="https://i.ibb.co/THG5mgB/2.jpg" alt="" />
      </div>
      <div className="slide">
        <img src="https://i.ibb.co/sWzZcrX/3.jpg" alt="" />
      </div>

      <div className="slide">
        <img src="https://i.ibb.co/qRX5hy0/4.jpg" alt="" />
      </div>
      <div className="slide">
        <img src="https://i.ibb.co/VwLzfzq/1.jpg" alt="" />
      </div>
      <div className="slide">
        <img src="https://i.ibb.co/THG5mgB/2.jpg" alt="" />
      </div>
      <div className="slide">
        <img src="https://i.ibb.co/sWzZcrX/3.jpg" alt="" />
      </div>

      <div className="slide">
        <img src="https://i.ibb.co/qRX5hy0/4.jpg" alt="" />
      </div>

      <div className="slide">
        <img src="https://i.ibb.co/VwLzfzq/1.jpg" alt="" />
      </div>
      <div className="slide">
        <img src="https://i.ibb.co/THG5mgB/2.jpg" alt="" />
      </div> */}
    </div>
  </div>
);

export default Publicarrusel;
